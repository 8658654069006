import "./App.css";
import Staking from "./components/Staking";

function App() {
  return (
    <div className="App">
      <Staking />
    </div>
  );
}

export default App;
